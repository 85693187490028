import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import Link from "../components/Global/Link";
import SEO from "../utils/SEO";

// Markup
const FourOhFourPage = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }
  const openGraph = {
    type: "website",
    title: doc.name,
    description: doc.headline,
  };

  const handleSubmitTest = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("contact5");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => alert("Form posted"))
      .catch((error) => console.log(error));
  };

  return (
    <Layout>
      <SEO openGraph={openGraph} />

      <section>
        <div className="container py-5">
          <form name="teamA" method="POST" data-netlify="true">
            GENERATE TEAM A
            <input type="hidden" name="form-name" value="teamA" />
            <input type="text" name="user" />
          </form>
          <form name="teamB" method="POST" data-netlify="true">
            GENERATE TEAM B
            <input type="hidden" name="form-name" value="teamB" />
            <input type="text" name="user" />
          </form>
        </div>
      </section>
      <hr />
    </Layout>
  );
};

export default FourOhFourPage;

export const QUERY = graphql`
  query FormTestQuery {
    index: storyblokEntry(slug: { eq: "rebelracingrivals" }) {
      content
    }
  }
`;
